import { Vue, Component, Prop, Ref } from 'vue-property-decorator';
@Component
export default class Upload extends Vue {
    @Prop({
        type: String,
        default: 'image/png, image/gif, image/jpeg, image/bmp, image/x-icon'
    })
    mimes!: string;
    @Prop({ type: Number, default: 4 }) maxSize!: number;
    @Ref() file!: any;
    @Prop() readonly value!: string;
    dataUrl = '';
    invalid = false;

    destroy() {
        this.$emit('input', null);
        this.file.value = '';
        this.dataUrl = '';
    }
}
