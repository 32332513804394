









































































import { Component, Mixins, Prop, Ref } from 'vue-property-decorator';
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';
import Upload from '@/mixins/upload';
import { ValidationProvider } from 'vee-validate';

@Component
export default class CropperUpload extends Mixins(Upload) {
    @Ref() img!: any;
    @Ref() fileProvider!: InstanceType<typeof ValidationProvider>;
    @Prop({ type: String }) rules!: string;
    @Prop({ type: String }) name!: string;
    @Prop({ type: String }) defaultImage!: string;
    @Prop({
        type: String,
        default:
            'content d-flex justify-content-center align-items-center w-100'
    })
    classCropper!: string;
    @Prop({ type: String }) urlPreview!: string;
    @Prop() upload!: Function;
    @Prop({
        default: () => ({
            aspectRatio: 1,
            autoCropArea: 1,
            viewMode: 1,
            movable: false,
            zoomable: false,
            guides: false,
            dragMode: 'none',
            minCropBoxWidth: 400,
            minCropBoxHeight: 500
        })
    })
    cropperOptions!: Cropper.Options;
    private cropper: Cropper | null = null;
    private isRequired = false;
    filename = '';
    preview = '';

    get rulesApplied() {
        return !!this.defaultImage && !this.isRequired
            ? 'required'
            : this.rules;
    }

    async load(files: FileList) {
        this.isRequired = true;
        await this.fileProvider.syncValue(this.defaultImage);
        const validationResult = await this.fileProvider.validate(files[0]);
        this.filename = files[0].name;
        const [url, { width, height }] = await this.readUploadedFile(files[0]);

        if (validationResult.valid) {
            this.isRequired = false;
            if (width <= 400 && height <= 500) {
                this.upload(this.send(files[0]));
                this.file.value = '';
                this.dataUrl = '';
                this.preview = url;
            } else {
                this.dataUrl = url;
            }
        } else {
            this.preview = url;
            this.destroy();
        }
    }

    createCropper() {
        this.cropper = new Cropper(this.img, this.cropperOptions);
    }

    pickImage() {
        this.file.click();
    }

    async submit() {
        if (this.cropper) {
            this.preview = this.cropper
                .getCroppedCanvas()
                .toDataURL('image/png'); // 
            const blob = await this.getBlob(this.cropper.getCroppedCanvas());
            this.upload(this.send(blob));
            this.file.value = '';
            this.dataUrl = '';
        }
    }

    send(value: Blob | File | null) {
        const form = new FormData();
        if (value) {
            form.append('document', value, this.filename);
        }
        return form;
    }

    private getBlob(canvas: HTMLCanvasElement): Promise<Blob | null> {
        return new Promise((resolve, reject) => {
            canvas.toBlob(
                (blob) => {
                    resolve(blob);
                },
                undefined,
                0.9
            );
        });
    }

    private readUploadedFile(
        file: File
    ): Promise<[string, { width: number; height: number }]> {
        const reader = new FileReader();
        return new Promise((resolve) => {
            reader.onload = (e) => {
                const img = new Image();
                img.src = reader.result as string;
                img.onload = function () {
                    const { width, height } = this as any;
                    resolve([img.src, { width, height }]);
                };
            };
            reader.readAsDataURL(file);
        });
    }
}
